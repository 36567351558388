.footer {
  width: 100%;
  background-color: #223E47;
  color: #ffffff; }
  .footer .footer_content_container {
    width: 88%;
    max-width: 1420px;
    margin: 64px auto;
    margin-bottom: 0;
    margin-top: 0; }
    .footer .footer_content_container .top {
      padding: 64px 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-start; }
      .footer .footer_content_container .top .footer_logo {
        width: 15%;
        min-width: 120px;
        cursor: pointer; }
      .footer .footer_content_container .top .language {
        width: 15%;
        min-width: 150px;
        display: flex;
        align-items: center;
        cursor: pointer; }
        .footer .footer_content_container .top .language img {
          height: 16px;
          margin-right: 4px; }
        .footer .footer_content_container .top .language p {
          margin-bottom: 0; }
      .footer .footer_content_container .top nav {
        display: flex;
        justify-content: space-between;
        width: 35%; }
        .footer .footer_content_container .top nav h6 {
          color: #ffffff;
          margin-bottom: 16px; }
        .footer .footer_content_container .top nav .nav_col a {
          display: block;
          cursor: pointer;
          margin-bottom: 16px;
          font-weight: 300;
          font-size: 14px;
          opacity: 0.8;
          color: #ffffff; }
          .footer .footer_content_container .top nav .nav_col a:hover {
            opacity: 0.6;
            text-decoration: none; }
  .footer .bottom {
    background-color: #00000010; }
    .footer .bottom .footer_bottom_content_container {
      width: 88%;
      max-width: 1420px;
      margin: 64px auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0;
      margin-top: 0;
      padding: 48px 0; }
      .footer .bottom .footer_bottom_content_container .logos {
        display: flex; }
        .footer .bottom .footer_bottom_content_container .logos img {
          height: 24px;
          margin-right: 24px; }
      .footer .bottom .footer_bottom_content_container p {
        font-size: 12px;
        color: #ffffff50;
        margin: 0; }
      .footer .bottom .footer_bottom_content_container .landing-footer_bottom-nav a {
        margin-left: 16px;
        text-decoration: none;
        color: #ffffff; }
        .footer .bottom .footer_bottom_content_container .landing-footer_bottom-nav a:first-child {
          margin-left: 0; }
        .footer .bottom .footer_bottom_content_container .landing-footer_bottom-nav a:hover {
          opacity: 0.7; }

@media only screen and (max-width: 992px) {
  .footer .footer_content_container .top {
    flex-direction: column;
    align-items: center; }
    .footer .footer_content_container .top nav {
      text-align: center; }
      .footer .footer_content_container .top nav .nav_col {
        margin: 16px 0; }
    .footer .footer_content_container .top .language {
      justify-content: center; }
  .footer .bottom .footer_bottom_content_container {
    flex-direction: column; }
    .footer .bottom .footer_bottom_content_container .logos {
      min-width: auto;
      flex-direction: column;
      align-items: center;
      margin-bottom: 24px; }
      .footer .bottom .footer_bottom_content_container .logos a {
        margin-bottom: 24px; }
      .footer .bottom .footer_bottom_content_container .logos img {
        margin: 0; } }

@media only screen and (max-width: 768px) {
  .footer .footer_content_container .top nav {
    flex-direction: column; }
  .footer .bottom .footer_bottom_content_container .landing-footer_bottom-nav {
    flex-direction: column;
    display: flex; }
    .footer .bottom .footer_bottom_content_container .landing-footer_bottom-nav a {
      margin: 16px 0;
      text-align: center; } }
