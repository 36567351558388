.external_links {
  margin-top: 48px; }
  .external_links img {
    width: 24px; }
  .external_links a {
    font-weight: 300; }
    .external_links a:hover span {
      color: #00D3D2; }
  .external_links h6 {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #808080;
    color: #ffffff; }
  .external_links .links a {
    color: #ffffff;
    width: 100%;
    margin-bottom: 16px;
    display: block; }
    .external_links .links a img {
      float: right; }
  .external_links.on-white a, .external_links.on-white h6, .external_links.on-white p {
    color: #3E3E3E; }
