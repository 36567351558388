.password-tooltip {
  -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.09); }
  .password-tooltip .tooltip-container {
    padding: 8px; }
    .password-tooltip .tooltip-container strong {
      margin-bottom: 16px;
      display: block; }
    .password-tooltip .tooltip-container .suggestion {
      display: flex;
      align-items: center;
      margin-bottom: 8px; }
      .password-tooltip .tooltip-container .suggestion:last-child {
        margin-bottom: 0; }
      .password-tooltip .tooltip-container .suggestion img {
        width: 16px;
        height: 16px;
        margin-right: 8px; }
      .password-tooltip .tooltip-container .suggestion p {
        margin-bottom: 0;
        font-size: 14px; }
    .password-tooltip .tooltip-container .gray-txt {
      color: #808080; }
