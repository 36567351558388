.flat-sidebar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 64px; }
  .flat-sidebar .navigation {
    padding-right: 48px;
    width: 25%; }
    .flat-sidebar .navigation .nav-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 0;
      border-bottom: 1px solid #CDCDCD;
      cursor: pointer; }
      .flat-sidebar .navigation .nav-item:hover {
        color: #00D3D2; }
      .flat-sidebar .navigation .nav-item.active-page {
        color: #00D3D2; }
        .flat-sidebar .navigation .nav-item.active-page img {
          width: 16px;
          height: 16px; }
  .flat-sidebar .flat-content-container {
    padding-left: 48px;
    border-left: 1px solid #CDCDCD;
    width: 75%; }

@media only screen and (max-width: 1200px) {
  .flat-sidebar {
    flex-direction: column; }
    .flat-sidebar .navigation {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0;
      border-bottom: 1px solid #CDCDCD;
      margin-bottom: 24px; }
      .flat-sidebar .navigation .nav-item {
        padding: 24px 16px;
        border-bottom: 2px solid #CDCDCD00;
        text-align: center; }
        .flat-sidebar .navigation .nav-item.active-page {
          border-bottom: 2px solid #00D3D2; }
        .flat-sidebar .navigation .nav-item img {
          display: none; }
    .flat-sidebar .flat-content-container {
      width: 100%;
      border-left: none;
      padding-left: 0; } }

@media only screen and (max-width: 576px) {
  .flat-sidebar .navigation .nav-item {
    padding: 16px 8px;
    font-size: 14px; } }
