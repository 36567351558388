.profile_page .container {
  position: relative; }
  .profile_page .container .user-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 64px;
    min-height: 100px; }
    .profile_page .container .user-info .user {
      display: flex;
      align-items: flex-end; }
      .profile_page .container .user-info .user .picture {
        background: #00D3D2;
        background: -moz-linear-gradient(left, #84DBFF 0%, #4AB1FF 100%);
        background: -webkit-linear-gradient(left, #84DBFF 0%, #4AB1FF 100%);
        background: linear-gradient(to right, #84DBFF 0%, #4AB1FF 100%);
        -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.09);
        -moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.09);
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.09);
        width: 120px;
        height: 120px;
        border-radius: 120px;
        display: flex;
        justify-content: center;
        align-items: center; }
        .profile_page .container .user-info .user .picture img {
          height: 64px;
          width: 64px; }
      .profile_page .container .user-info .user .name-edit-pic {
        margin-top: 48px; }

.profile_page .delete-account-button {
  margin-top: 64px;
  padding: 8px 16px;
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
  color: #808080;
  border: 1px solid #E5E5E5;
  border-radius: 24px;
  cursor: pointer; }
  .profile_page .delete-account-button:hover {
    background-color: #F2F2F2; }

.profile_page .modal_content .delete-modal-content .title {
  margin-bottom: 24px;
  text-align: center; }

.profile_page .modal_content .delete-modal-content .buttons {
  display: flex;
  justify-content: center; }
  .profile_page .modal_content .delete-modal-content .buttons button:first-child {
    margin-right: 16px; }

.profile_page .user_setting .phone_number_inputs {
  display: flex; }
  .profile_page .user_setting .phone_number_inputs .select_input {
    margin-right: 24px;
    background-color: #E5E5E5; }

@media only screen and (max-width: 992px) {
  .profile_page .container .user-info {
    margin-bottom: 24px;
    min-height: auto; }
    .profile_page .container .user-info .user {
      margin: 0 auto; }
      .profile_page .container .user-info .user .name-edit-pic {
        margin-top: 24px; }
  .profile_page .user_setting .main_button {
    width: 100%; }
  .profile_page .password_setting .main_button {
    width: 100%; } }
