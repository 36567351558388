.message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; }
  .message img {
    height: 88px;
    margin-bottom: 24px; }
  .message h4 {
    margin-bottom: 8px; }
  .message .small-note {
    color: #3E3E3E; }
