.upgrade-modal-content .description-text {
  margin-bottom: 48px; }
  .upgrade-modal-content .description-text span {
    font-weight: 300; }

.upgrade-modal-content .profile-details {
  margin-bottom: 48px; }
  .upgrade-modal-content .profile-details span {
    display: block;
    font-weight: 300;
    margin-bottom: 4px; }

.upgrade-modal-content .button-n-coupon-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 48px; }
  .upgrade-modal-content .button-n-coupon-container .coupon-container .coupon_form {
    position: relative; }
    .upgrade-modal-content .button-n-coupon-container .coupon-container .coupon_form .coupon_form_buttons {
      position: absolute;
      top: 12px;
      right: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .upgrade-modal-content .button-n-coupon-container .coupon-container .coupon_form .coupon_form_buttons .add-coupon-button {
        width: 16px;
        cursor: pointer; }
      .upgrade-modal-content .button-n-coupon-container .coupon-container .coupon_form .coupon_form_buttons .close-coupon-button {
        border-left: 1px solid #E5E5E5;
        width: 20px;
        cursor: pointer;
        margin-left: 4px;
        padding-left: 4px; }
    .upgrade-modal-content .button-n-coupon-container .coupon-container .coupon_form .form_input {
      margin-bottom: 0; }
      .upgrade-modal-content .button-n-coupon-container .coupon-container .coupon_form .form_input .label {
        display: none; }
      .upgrade-modal-content .button-n-coupon-container .coupon-container .coupon_form .form_input input {
        height: 40px; }
  .upgrade-modal-content .button-n-coupon-container .coupon-container .success-note {
    font-size: 14px;
    cursor: pointer; }
  .upgrade-modal-content .button-n-coupon-container .buttons {
    display: flex;
    justify-content: flex-end; }
    .upgrade-modal-content .button-n-coupon-container .buttons .primary {
      margin-left: 16px; }
      .upgrade-modal-content .button-n-coupon-container .buttons .primary .white_bg_for_hover {
        padding: 8px 48px; }

.upgrade-modal-content .upgrade-modal_form .sale-price-container {
  margin-right: 16px; }

@media only screen and (max-width: 768px) {
  .upgrade-modal-content .button-n-coupon-container {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 16px; }
    .upgrade-modal-content .button-n-coupon-container .coupon-container {
      margin: 24px auto;
      text-align: center; }
    .upgrade-modal-content .button-n-coupon-container .buttons {
      margin: 0 auto; } }

@media only screen and (max-width: 576px) {
  .upgrade-modal-content .button-n-coupon-container .buttons {
    flex-direction: column; }
    .upgrade-modal-content .button-n-coupon-container .buttons .primary {
      margin: 0; }
    .upgrade-modal-content .button-n-coupon-container .buttons .outlined {
      margin: 0 0 16px 0; }
    .upgrade-modal-content .button-n-coupon-container .buttons button {
      width: 100%; } }
