.dashboard {
  min-height: calc(100vh - 170px);
  margin-top: 48px; }
  .dashboard .upgrade-modal .modal_content {
    width: 80%; }
  .dashboard .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .dashboard .dashboard-header .toggle-store .text {
      margin-right: 8px;
      font-size: 14px;
      color: #808080; }
  .dashboard .dashboard-content {
    display: flex;
    justify-content: space-between;
    margin-top: 24px; }
    .dashboard .dashboard-content .main_card {
      margin: 0 0 24px 0;
      padding: 24px;
      width: 100%; }
    .dashboard .dashboard-content .col-first {
      width: 60%; }
    .dashboard .dashboard-content .col-second {
      width: 38%; }
    .dashboard .dashboard-content .manage-store-card .card_content {
      width: 100%;
      max-width: none; }
      .dashboard .dashboard-content .manage-store-card .card_content .manage-store-content {
        background-repeat: no-repeat;
        background-position-x: right;
        background-position-y: bottom;
        background-size: 50%; }
        .dashboard .dashboard-content .manage-store-card .card_content .manage-store-content .store-info {
          padding-left: 16px;
          border-left: 2px solid #00D3D2;
          margin-bottom: 16px; }
          .dashboard .dashboard-content .manage-store-card .card_content .manage-store-content .store-info div {
            margin-bottom: 8px; }
        .dashboard .dashboard-content .manage-store-card .card_content .manage-store-content .note-text {
          width: 48%;
          min-width: 400px; }
          .dashboard .dashboard-content .manage-store-card .card_content .manage-store-content .note-text .title {
            font-weight: 700; }
          .dashboard .dashboard-content .manage-store-card .card_content .manage-store-content .note-text p, .dashboard .dashboard-content .manage-store-card .card_content .manage-store-content .note-text span {
            display: inline; }
        .dashboard .dashboard-content .manage-store-card .card_content .manage-store-content .buttons-container {
          display: flex;
          margin-top: 48px; }
          .dashboard .dashboard-content .manage-store-card .card_content .manage-store-content .buttons-container a, .dashboard .dashboard-content .manage-store-card .card_content .manage-store-content .buttons-container button {
            margin: 0; }
          .dashboard .dashboard-content .manage-store-card .card_content .manage-store-content .buttons-container button:first-child {
            margin-right: 24px; }
    .dashboard .dashboard-content .manage-store-card .days-counter {
      float: right; }
    .dashboard .dashboard-content .manage-store-card .store-image {
      display: none; }
    .dashboard .dashboard-content .sale-on-fb-card {
      padding: 0 0 0 24px; }
      .dashboard .dashboard-content .sale-on-fb-card .card_content {
        width: 100%;
        max-width: none; }
        .dashboard .dashboard-content .sale-on-fb-card .card_content .sell-on-fb-section {
          display: flex;
          justify-content: space-between;
          align-items: stretch; }
          .dashboard .dashboard-content .sale-on-fb-card .card_content .sell-on-fb-section .text {
            width: 55%;
            padding: 24px 0; }
          .dashboard .dashboard-content .sale-on-fb-card .card_content .sell-on-fb-section .facebook-btn-container {
            width: 42%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-repeat: no-repeat;
            background-size: cover; }
            .dashboard .dashboard-content .sale-on-fb-card .card_content .sell-on-fb-section .facebook-btn-container .facebook-btn {
              width: 60%; }
              .dashboard .dashboard-content .sale-on-fb-card .card_content .sell-on-fb-section .facebook-btn-container .facebook-btn:hover {
                opacity: 0.9; }
    .dashboard .dashboard-content .checkout-card .card_content {
      width: 100%;
      max-width: none; }
      .dashboard .dashboard-content .checkout-card .card_content .checkout-container .checkout-logo {
        height: 48px;
        margin-bottom: 24px; }
      .dashboard .dashboard-content .checkout-card .card_content .checkout-container .card-types {
        float: right;
        height: 24px; }
    .dashboard .dashboard-content .domains-card .card_content {
      width: 100%;
      max-width: none; }
      .dashboard .dashboard-content .domains-card .card_content .content-container .text h4 {
        margin-bottom: 8px; }
      .dashboard .dashboard-content .domains-card .card_content .content-container .text .status-text {
        display: flex;
        margin-bottom: 24px;
        align-items: center; }
        .dashboard .dashboard-content .domains-card .card_content .content-container .text .status-text img {
          width: 16px;
          height: 16px;
          margin-left: 8px; }
        .dashboard .dashboard-content .domains-card .card_content .content-container .text .status-text p {
          margin: 0; }
      .dashboard .dashboard-content .domains-card .card_content .content-container .button-n-logo {
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .dashboard .dashboard-content .domains-card .card_content .content-container .button-n-logo img {
          width: 120px; }
        .dashboard .dashboard-content .domains-card .card_content .content-container .button-n-logo button {
          margin: 0; }
    .dashboard .dashboard-content .download-app-card {
      padding: 0 0 0 24px; }
      .dashboard .dashboard-content .download-app-card .card_content {
        width: 100%;
        max-width: none; }
        .dashboard .dashboard-content .download-app-card .card_content .app-card-content-container {
          display: flex; }
          .dashboard .dashboard-content .download-app-card .card_content .app-card-content-container .content-section {
            width: 55%;
            padding: 24px 0; }
            .dashboard .dashboard-content .download-app-card .card_content .app-card-content-container .content-section .download-btns {
              display: flex;
              justify-content: space-between;
              align-items: center; }
              .dashboard .dashboard-content .download-app-card .card_content .app-card-content-container .content-section .download-btns a {
                border: 1px solid #CDCDCD;
                border-radius: 4px;
                width: 49%;
                max-width: 130px; }
                .dashboard .dashboard-content .download-app-card .card_content .app-card-content-container .content-section .download-btns a:hover {
                  background-color: #F3F5F6; }
          .dashboard .dashboard-content .download-app-card .card_content .app-card-content-container .image-section {
            width: 45%;
            display: flex;
            justify-content: center;
            background-color: #00D3D210;
            -webkit-clip-path: polygon(29% 0, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(29% 0, 100% 0, 100% 100%, 0% 100%); }
            .dashboard .dashboard-content .download-app-card .card_content .app-card-content-container .image-section img {
              width: 60%; }
      .dashboard .dashboard-content .download-app-card .responsive-image-section {
        display: none; }

@media only screen and (max-width: 1366px) {
  .dashboard .dashboard-content .manage-store-card .manage-store-content {
    background-image: url("/remove") !important; } }

@media only screen and (max-width: 992px) {
  .dashboard .dashboard-content {
    flex-direction: column; }
    .dashboard .dashboard-content .col-first, .dashboard .dashboard-content .col-second {
      width: 100%; }
    .dashboard .dashboard-content .upgrade-modal .modal_content {
      width: 100%; }
    .dashboard .dashboard-content .download-app-card .card_content .app-card-content-container .content-section .download-btns {
      justify-content: flex-start; }
      .dashboard .dashboard-content .download-app-card .card_content .app-card-content-container .content-section .download-btns a {
        margin-right: 16px; }
    .dashboard .dashboard-content .manage-store-card .card_content .manage-store-content .note-text {
      width: 100%;
      min-width: auto;
      word-break: break-word; }
    .dashboard .dashboard-content .manage-store-card .card_content .manage-store-content .store-image {
      display: block;
      margin: 24px auto;
      width: 80%; }
    .dashboard .dashboard-content .manage-store-card .card_content .manage-store-content .buttons-container {
      flex-direction: column; }
      .dashboard .dashboard-content .manage-store-card .card_content .manage-store-content .buttons-container a, .dashboard .dashboard-content .manage-store-card .card_content .manage-store-content .buttons-container button {
        margin: 0;
        width: 100%; }
      .dashboard .dashboard-content .manage-store-card .card_content .manage-store-content .buttons-container button:first-child {
        margin-bottom: 16px; }
    .dashboard .dashboard-content .manage-store-card .days-counter {
      display: none; }
    .dashboard .dashboard-content .sale-on-fb-card {
      padding: 0; }
      .dashboard .dashboard-content .sale-on-fb-card .card_content {
        width: 100%;
        max-width: none; }
        .dashboard .dashboard-content .sale-on-fb-card .card_content .sell-on-fb-section {
          flex-direction: column; }
          .dashboard .dashboard-content .sale-on-fb-card .card_content .sell-on-fb-section .text {
            width: 100%;
            padding: 24px; }
          .dashboard .dashboard-content .sale-on-fb-card .card_content .sell-on-fb-section .facebook-btn-container {
            width: 100%;
            height: 150px;
            display: flex;
            padding: 24px;
            margin-top: 24px; }
            .dashboard .dashboard-content .sale-on-fb-card .card_content .sell-on-fb-section .facebook-btn-container .facebook-btn {
              width: 50%;
              max-width: 150px;
              min-width: 150px; } }

@media only screen and (max-width: 576px) {
  .dashboard .dashboard-content .manage-store-card .brand-url-text {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block; }
  .dashboard .dashboard-content .domains-card .card_content .content-container .button-n-logo img {
    display: none; }
  .dashboard .dashboard-content .domains-card .card_content .content-container .button-n-logo a {
    width: 100%; }
    .dashboard .dashboard-content .domains-card .card_content .content-container .button-n-logo a button {
      width: 100%; }
  .dashboard .dashboard-content .download-app-card {
    padding: 0 0 0 24px; }
    .dashboard .dashboard-content .download-app-card .card_content .app-card-content-container {
      flex-direction: column; }
      .dashboard .dashboard-content .download-app-card .card_content .app-card-content-container .content-section {
        width: 100%;
        padding: 24px 24px 24px 0; }
        .dashboard .dashboard-content .download-app-card .card_content .app-card-content-container .content-section .responsive-image-section {
          display: block;
          width: 70%;
          margin: 24px auto;
          max-width: 170px; }
        .dashboard .dashboard-content .download-app-card .card_content .app-card-content-container .content-section .download-btns {
          justify-content: space-between; }
          .dashboard .dashboard-content .download-app-card .card_content .app-card-content-container .content-section .download-btns a {
            max-width: none;
            margin-right: 0; }
      .dashboard .dashboard-content .download-app-card .card_content .app-card-content-container .image-section {
        display: none; }
  .dashboard .dashboard-content .checkout-card .card_content .checkout-container .card-types {
    display: none; } }
