.form_input {
  display: flex;
  position: relative;
  margin-bottom: 12px; }
  .form_input .hidden {
    display: none; }
  .form_input, .form_input .input_div, .form_input .input {
    width: 100%; }
  .form_input .label {
    font-size: 14px;
    color: #CDCDCD;
    position: absolute;
    margin-left: 16px;
    margin-top: 24px;
    opacity: 0;
    z-index: -1; }
  .form_input .label.show_label {
    opacity: 1;
    margin-top: 8px;
    font-size: 10px;
    z-index: 0; }
  .form_input .input {
    height: 64px;
    border: 1px solid #CDCDCD;
    border-radius: 4px;
    padding: 4px 16px;
    font-size: 14px;
    background: transparent;
    -moz-appearance: textfield; }
  .form_input .input.error {
    border-color: #EB001B;
    outline: none; }
  .form_input .valid-input:focus {
    border-color: #00D3D2;
    outline: none; }
  .form_input input:-webkit-autofill,
  .form_input input:-webkit-autofill:hover,
  .form_input input:-webkit-autofill:focus,
  .form_input input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s; }
  .form_input .feedback {
    font-size: 12px;
    color: #EB001B;
    opacity: 0;
    display: block;
    height: 14px;
    margin-top: 4px; }
  .form_input .feedback.show {
    opacity: 1; }
  .form_input .icon {
    height: 24px;
    cursor: pointer;
    position: absolute;
    top: 22px;
    right: 16px;
    padding-left: 8px; }

.select_input {
  height: 64px;
  border: 1px solid #CDCDCD;
  border-radius: 4px;
  padding: 4px 16px;
  font-size: 14px; }

.text-area .input {
  padding-top: 24px;
  height: 120px; }

.text-area textarea {
  resize: none; }
  .text-area textarea:focus {
    border-color: #00D3D2; }

.select-field {
  display: flex;
  margin-bottom: 24px;
  position: relative;
  width: 100%; }
  .select-field .label {
    color: #808080;
    position: absolute;
    margin-left: 16px;
    margin-top: 24px;
    opacity: 1;
    font-size: 14px;
    z-index: -1;
    width: 100%; }
  .select-field .label.selected {
    color: #CDCDCD;
    margin-left: 16px;
    margin-top: 8px;
    font-size: 10px; }
  .select-field select {
    height: 64px;
    border: 1px solid #CDCDCD;
    border-radius: 4px;
    padding: 4px 16px;
    font-size: 14px;
    background: transparent;
    outline: none;
    width: 100%;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none; }
    .select-field select.error {
      border-color: #EB001B; }
    .select-field select:focus {
      border-color: #00D3D2;
      outline: none; }
    .select-field select:-webkit-autofill, .select-field select:-webkit-autofill:hover, .select-field select:-webkit-autofill:focus, .select-field select:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s; }
  .select-field .arrow-icon {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 16px;
    top: 24px;
    transform: rotate(-90deg);
    z-index: -1; }
    .select-field .arrow-icon.select-open {
      transform: rotate(90deg); }
