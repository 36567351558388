.main_card {
  -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.09);
  width: 88%;
  max-width: 1420px;
  margin: 64px auto;
  padding: 64px 48px;
  margin: 64px auto;
  background-repeat: no-repeat;
  background-position: right;
  background-color: #ffffff;
  border-radius: 4px; }
  .main_card.fullWidth {
    width: 100%;
    padding: 24px;
    margin: 0;
    max-width: none; }
    .main_card.fullWidth .card_content {
      width: 100%;
      max-width: 100%; }
      .main_card.fullWidth .card_content p {
        margin-bottom: 0; }
  .main_card .card_content {
    max-width: 480px;
    width: 48%; }
    .main_card .card_content h1, .main_card .card_content h2, .main_card .card_content h3, .main_card .card_content h4, .main_card .card_content h5, .main_card .card_content h6, .main_card .card_content p, .main_card .card_content button {
      margin-bottom: 24px; }
