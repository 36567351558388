.defaultLayout {
  background-color: #ffffff; }
  .defaultLayout .main_modal.session_ends_notification .modal_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center; }
    .defaultLayout .main_modal.session_ends_notification .modal_content button {
      margin-top: 24px; }
  .defaultLayout .help-section .content-container {
    width: 50%;
    margin-bottom: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .defaultLayout .help-section .content-container img {
      max-height: 120px;
      margin-right: 24px; }
    .defaultLayout .help-section .content-container .content .title {
      margin-bottom: 8px; }
    .defaultLayout .help-section .content-container .content .help-btn {
      display: flex;
      margin-top: 24px; }
      .defaultLayout .help-section .content-container .content .help-btn img {
        width: 24px;
        height: 24px;
        margin-right: 8px; }
  @media only screen and (max-width: 992px) {
    .defaultLayout .help-section .content-container {
      width: 100%; }
      .defaultLayout .help-section .content-container img {
        max-height: 80px; } }
  @media only screen and (max-width: 576px) {
    .defaultLayout .help-section .content-container .big-icon {
      display: none; } }
