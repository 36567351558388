.register-component {
  background-color: #F5F6F6;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0; }
  @media (max-height: 789px) {
    .register-component .form_input, .register-component .select-field {
      height: 64px;
      margin-bottom: 0 !important; } }
  .register-component .message {
    width: 100%;
    height: 100%;
    margin: auto;
    background: #f8fafb;
    overflow: hidden;
    padding: 20px 30px; }
    @media (min-width: 768px) {
      .register-component .message {
        width: 80%;
        max-width: 1318px;
        height: 85%;
        max-height: 910px;
        box-shadow: 0px 1px 40px rgba(0, 0, 0, 0.1);
        border-radius: 10px; } }
    @media (min-height: 863px) and (max-height: 900px) {
      .register-component .message {
        min-height: 810px; } }
    @media (max-height: 850px) {
      .register-component .message {
        min-height: 745px; } }
    @media (max-width: 420px) {
      .register-component .message {
        padding: 0 20px !important; }
        .register-component .message > p {
          width: 90%; }
        .register-component .message h4 {
          width: 90%; } }
  .register-component #icon_password_register-password {
    top: 18px !important; }
  .register-component .select-field .label:not(.selected) {
    color: #B3B3B3;
    position: absolute;
    margin-left: 16px;
    margin-top: 15px;
    opacity: 1;
    font-size: 16px;
    z-index: 0;
    width: auto !important;
    text-align: left;
    display: inline-block; }
  .register-component .arrow-icon {
    z-index: 0; }
  .register-component .form_input {
    margin-bottom: 0 !important; }
  .register-component input.input {
    background: white !important;
    border-radius: 2px !important;
    height: 56px !important;
    color: #333 !important;
    font-size: 16px !important; }
  .register-component input.input:not(.error) {
    border: 1px solid #e8e8e8 !important; }
  .register-component input.input:not(.valid-input) {
    border: 1px solid #e8e8e8 !important; }
  .register-component input.input:not(.error):focus {
    border: 1px solid #808080 !important; }
  .register-component input.input.error {
    border: 1px solid #E32929 !important; }
  .register-component input.input.valid-input:not(:placeholder-shown) {
    border: 1px solid #0170E3 !important; }
  .register-component select.select-input-categoryKey {
    background: white !important;
    border-radius: 2px !important;
    height: 56px !important;
    color: #333 !important;
    font-size: 16px !important; }
  .register-component select.select-input-categoryKey:not(.error) {
    border: 1px solid #e8e8e8 !important; }
  .register-component select.select_input {
    background: white !important;
    border-radius: 2px !important;
    height: 56px !important;
    color: #b3b3b3 !important;
    font-size: 16px !important; }
  .register-component select.select_input:not(.error) {
    border: 1px solid #e8e8e8 !important; }
  .register-component select.select_input:disabled {
    background: #F2F2F2 !important;
    border: 1px solid #E8E8E8 !important;
    border-radius: 2px !important;
    color: #000000 !important;
    font-size: 16px !important;
    margin-left: 1px !important; }
  .register-component ::placeholder {
    color: #B3B3B3 !important;
    font-weight: normal !important; }
  @media (min-width: 1367px) and (max-width: 1440px) {
    .register-component {
      background-position: top center;
      background-size: 85%; } }
  @media (max-width: 1366px) {
    .register-component {
      background-position: bottom center;
      background-size: 84%; } }
  @media (min-width: 768px) {
    .register-component {
      height: 100vh; } }
  .register-component .register-page-title {
    text-align: left;
    margin-bottom: 37px;
    font-size: 26px; }
    @media (min-height: 863px) and (max-height: 900px) {
      .register-component .register-page-title {
        margin-bottom: 25px; } }
    @media (min-height: 790px) and (max-height: 850px) {
      .register-component .register-page-title {
        margin-bottom: 20px; } }
    @media (max-height: 789px) {
      .register-component .register-page-title {
        margin-bottom: 10px;
        font-size: 23px; } }
    @media (max-width: 415px) {
      .register-component .register-page-title {
        margin-top: 55px; } }
  .register-component .right {
    position: relative;
    padding: 0;
    margin: 0;
    width: 100%;
    min-width: 300px;
    max-width: 486px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .register-component .phone_number_inputs {
    display: flex; }
    .register-component .phone_number_inputs select {
      margin-right: 16px;
      min-width: 88px; }
  .register-component .password_recommendation_text {
    margin: 16px 0;
    font-size: 12px;
    color: #808080; }
  .register-component .terms_n_privacy_section {
    font-size: 14px;
    margin-bottom: 18px;
    text-align: right;
    color: #0170e3;
    font-weight: bold; }
    .register-component .terms_n_privacy_section a {
      color: #0170E3 !important; }
      .register-component .terms_n_privacy_section a:hover {
        color: #399AFF !important; }
  .register-component .terms_n_privacy_text {
    font-size: 14px;
    text-align: center;
    color: #000000;
    display: block; }
  .register-component .centered_button {
    display: flex;
    justify-content: center;
    align-items: center; }
    .register-component .centered_button button {
      width: 100%; }
  .register-component .have_account {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 300;
    margin-top: 24px;
    text-align: center; }
    .register-component .have_account span {
      width: 24%;
      height: 1px;
      background-color: #E5E5E5; }
  .register-component .main_error_message {
    text-align: center;
    margin-top: -14px; }
  .register-component .recaptcha-container {
    height: 100px;
    align-items: flex-start; }

.form_container {
  width: 100%; }

.CardWhite.RegisterGrid {
  width: 100%;
  height: 100%;
  margin: auto;
  background: #f8fafb;
  overflow: hidden;
  padding: 20px 30px; }
  @media (min-width: 768px) {
    .CardWhite.RegisterGrid {
      width: 80%;
      max-width: 1318px;
      height: 85%;
      max-height: 910px;
      box-shadow: 0px 1px 40px rgba(0, 0, 0, 0.1);
      border-radius: 10px; } }
  @media (min-height: 863px) and (max-height: 900px) {
    .CardWhite.RegisterGrid {
      min-height: 810px; } }
  @media (min-height: 790px) and (max-height: 850px) {
    .CardWhite.RegisterGrid {
      min-height: 745px; } }
  @media (max-height: 789px) {
    .CardWhite.RegisterGrid {
      height: 90%; } }

@media (min-width: 992px) {
  .RegisterGrid {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 0px; } }

.RegisterImage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  position: relative; }
  @media (max-width: 991px) {
    .RegisterImage {
      display: none; } }
  .RegisterImage .positionAbsolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 145px; }
    @media (max-width: 1366px) {
      .RegisterImage .positionAbsolute {
        left: 0; } }
  .RegisterImage img {
    max-width: 554px;
    width: 95%; }
    @media (min-width: 1367px) {
      .RegisterImage img {
        width: 100%; } }
    @media (min-width: 1199px) and (max-width: 1366px) {
      .RegisterImage img {
        width: 100%; } }

.bgBlueButton {
  color: #ffffff;
  text-transform: uppercase;
  padding: 18px 48px !important;
  width: 100%;
  background: #0170e3 !important;
  box-shadow: 0px 6px 6px rgba(143, 235, 227, 0.25) !important;
  border-radius: 2px !important;
  font-size: 16px !important;
  font-weight: normal !important; }
  .bgBlueButton:hover {
    box-shadow: 0px 6px 6px rgba(143, 235, 227, 0.25) !important;
    background: #399aff !important; }

.RegisterFormSection {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative; }
  .RegisterFormSection .positionAbsolute1 {
    position: absolute;
    top: 0;
    right: 0;
    width: 145px; }
    @media (min-width: 992px) {
      .RegisterFormSection .positionAbsolute1 {
        display: none; } }

.login_section {
  margin: 30px 0;
  color: #333333;
  font-size: 16px;
  font-weight: bold; }
  @media (max-height: 850px) {
    .login_section {
      margin: 15px 0; } }
  @media (max-width: 767px) {
    .login_section {
      width: 100%;
      text-align: left; } }
  .login_section a {
    color: #0170E3 !important; }
    .login_section a:hover {
      color: #399AFF !important; }

.login_section1 {
  margin: 30px 0; }
  @media (max-height: 850px) {
    .login_section1 {
      margin: 15px 0; } }
  .login_section1 a {
    color: white !important; }

.beforeAfterSection {
  margin-bottom: 44px;
  width: 100%;
  position: relative;
  z-index: 1;
  padding: 0 24px; }
  .beforeAfterSection:before {
    border-top: 1px solid #b3b3b3;
    content: "";
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 90%;
    max-width: 486px;
    min-width: 300px;
    z-index: -1; }
    @media (max-width: 360px) {
      .beforeAfterSection:before {
        min-width: 275px; } }
  .beforeAfterSection span {
    background: #f8fafb;
    padding: 0 15px;
    color: #b3b3b3;
    font-size: 12px; }

.Soc_Section {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center; }

.socButton {
  background: white !important;
  border: 1px solid #4867aa !important;
  border-radius: 2px !important;
  padding: 14px 20px !important;
  width: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }
  @media (min-width: 400px) {
    .socButton {
      padding: 14px 40px !important;
      width: 146px; } }
  .socButton:hover {
    background: transparent !important; }

.F1, .G1 {
  display: none; }

.FbBlue {
  width: 100%;
  height: 100%;
  border: 1px solid #4867aa !important; }
  .FbBlue:hover {
    background: #4867aa !important; }
    .FbBlue:hover .F1 {
      display: flex; }
    .FbBlue:hover .F {
      display: none; }

.GRed {
  width: 100%;
  height: 100%;
  border: 1px solid #da4f42 !important;
  margin-left: 24px; }
  .GRed:hover {
    background: #da4f42 !important; }
    .GRed:hover .G1 {
      display: flex; }
    .GRed:hover .G {
      display: none; }

@media (min-width: 768px) {
  .regGridTwo {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 0px; } }

@media (max-height: 850px) {
  .register-component .recaptcha-container {
    height: 90px; } }

@media (max-height: 850px) {
  .register-component .beforeAfterSection {
    margin-bottom: 20px; } }

@media (max-width: 1199px) {
  .register-component .socButton {
    padding: 8px 40px !important;
    width: 146px; } }

.register-component select.select-input-categoryKey {
  background: none !important; }
