.billing-end-invoice-page .table {
  padding: 0;
  background-color: #ffffff; }
  .billing-end-invoice-page .table .table-header {
    display: flex;
    padding: 24px;
    border-bottom: 1px dashed #CDCDCD; }
  .billing-end-invoice-page .table .table-row {
    display: flex;
    padding: 24px;
    border-bottom: 1px solid #CDCDCD; }
    .billing-end-invoice-page .table .table-row:hover {
      background-color: #2af59a10; }
    .billing-end-invoice-page .table .table-row img {
      width: 24px;
      height: 24px;
      margin-right: 8px; }
    .billing-end-invoice-page .table .table-row p {
      font-size: 14px; }
    .billing-end-invoice-page .table .table-row .status-text {
      display: flex;
      font-size: 14px;
      align-items: center; }
      .billing-end-invoice-page .table .table-row .status-text img {
        margin-left: 8px;
        cursor: pointer;
        width: 16px;
        height: 16px; }
  .billing-end-invoice-page .table .tb-col-1, .billing-end-invoice-page .table .tb-col-2, .billing-end-invoice-page .table .tb-col-3, .billing-end-invoice-page .table .tb-col-4, .billing-end-invoice-page .table .tb-col-5, .billing-end-invoice-page .table .tb-col-6 {
    display: flex;
    align-items: center; }
    .billing-end-invoice-page .table .tb-col-1 h6, .billing-end-invoice-page .table .tb-col-1 button, .billing-end-invoice-page .table .tb-col-2 h6, .billing-end-invoice-page .table .tb-col-2 button, .billing-end-invoice-page .table .tb-col-3 h6, .billing-end-invoice-page .table .tb-col-3 button, .billing-end-invoice-page .table .tb-col-4 h6, .billing-end-invoice-page .table .tb-col-4 button, .billing-end-invoice-page .table .tb-col-5 h6, .billing-end-invoice-page .table .tb-col-5 button, .billing-end-invoice-page .table .tb-col-6 h6, .billing-end-invoice-page .table .tb-col-6 button {
      margin: 0; }
  .billing-end-invoice-page .table .tb-col-1, .billing-end-invoice-page .table .tb-col-3, .billing-end-invoice-page .table .tb-col-4 {
    width: 17%; }
  .billing-end-invoice-page .table .tb-col-2 {
    width: 20%; }
  .billing-end-invoice-page .table .tb-col-5, .billing-end-invoice-page .table .tb-col-6 {
    width: 14.5%; }
  .billing-end-invoice-page .table .tb-col-4 {
    flex-direction: column; }
    .billing-end-invoice-page .table .tb-col-4 p {
      width: 100%; }
  .billing-end-invoice-page .table .empty-billings {
    text-align: center;
    padding: 48px; }
  .billing-end-invoice-page .table .see-pdf-icon {
    display: none; }

.billing-end-invoice-page .main-pagination {
  margin-top: 48px;
  float: right; }

.billing-end-invoice-page .load-more-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  width: 48px;
  height: 48px;
  border-radius: 48px;
  background-color: #00D3D230; }
  .billing-end-invoice-page .load-more-btn img {
    height: 24px;
    transform: rotate(90deg); }
  .billing-end-invoice-page .load-more-btn:hover {
    background-color: #00D3D240;
    cursor: pointer; }

@media only screen and (max-width: 992px) {
  .billing-end-invoice-page .table {
    margin-top: 24px; } }

@media only screen and (max-width: 768px) {
  .billing-end-invoice-page .table .table-header {
    display: none; }
  .billing-end-invoice-page .table .table-row {
    display: grid;
    grid-template-columns: 24px auto auto 24px;
    grid-template-rows: 24px 24px 24px;
    grid-gap: 16px; }
    .billing-end-invoice-page .table .table-row .tb-col-1, .billing-end-invoice-page .table .table-row .tb-col-2, .billing-end-invoice-page .table .table-row .tb-col-3, .billing-end-invoice-page .table .table-row .tb-col-4, .billing-end-invoice-page .table .table-row .tb-col-5, .billing-end-invoice-page .table .table-row .tb-col-6 {
      width: auto; }
    .billing-end-invoice-page .table .table-row .tb-col-1 {
      grid-column: 1;
      grid-row: 1 / 4; }
      .billing-end-invoice-page .table .table-row .tb-col-1 p {
        display: none; }
    .billing-end-invoice-page .table .table-row .tb-col-2 {
      grid-column: 2 / 2;
      grid-row: 1/ 2; }
    .billing-end-invoice-page .table .table-row .tb-col-3 {
      grid-column: 2;
      grid-row: 2; }
    .billing-end-invoice-page .table .table-row .tb-col-4 {
      grid-column: 3;
      grid-row: 1; }
    .billing-end-invoice-page .table .table-row .tb-col-5 {
      grid-column: 3;
      grid-row: 3; }
    .billing-end-invoice-page .table .table-row .tb-col-6 {
      grid-column: 2;
      grid-row: 3; }
      .billing-end-invoice-page .table .table-row .tb-col-6 img {
        display: none; }
    .billing-end-invoice-page .table .table-row .see-pdf-icon {
      display: block;
      grid-column: 4;
      grid-row: 2;
      cursor: pointer; } }
