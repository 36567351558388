.sidebar {
  width: 224px;
  position: fixed;
  background-color: #3E3E3E;
  padding: 16px;
  color: #ffffff;
  left: 0;
  transition: left 0.5s; }
  .sidebar .sidebar_content {
    height: calc(100vh - 68px);
    overflow-x: auto; }
    .sidebar .sidebar_content header img {
      height: 48px;
      cursor: pointer; }
    .sidebar .sidebar_content .navigation {
      font-weight: 300; }
      .sidebar .sidebar_content .navigation .nav_top_item {
        transition: all 0.3s; }
        .sidebar .sidebar_content .navigation .nav_top_item:hover a {
          color: #00D3D2; }
        .sidebar .sidebar_content .navigation .nav_top_item a {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #808080;
          padding: 16px 0 16px 8px;
          color: #ffffff; }
          .sidebar .sidebar_content .navigation .nav_top_item a p {
            margin: 0 8px; }
          .sidebar .sidebar_content .navigation .nav_top_item a.hasDropdown {
            justify-content: space-between; }
            .sidebar .sidebar_content .navigation .nav_top_item a.hasDropdown p {
              margin: 0; }
      .sidebar .sidebar_content .navigation .collapse_nav {
        margin: 16px 0 16px 16px;
        border-bottom: 1px solid #808080; }
        .sidebar .sidebar_content .navigation .collapse_nav div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 0; }
          .sidebar .sidebar_content .navigation .collapse_nav div p {
            margin: 0; }
  .sidebar .navigation, .sidebar footer {
    margin-top: 48px; }
    .sidebar .navigation img, .sidebar footer img {
      width: 24px; }
    .sidebar .navigation .img-change-on-hover, .sidebar footer .img-change-on-hover {
      width: 24px;
      height: 24px;
      background-size: cover;
      background: no-repeat center; }
    .sidebar .navigation a, .sidebar footer a {
      font-weight: 300; }
  .sidebar footer {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 16px; }
    .sidebar footer p {
      margin: 0 8px; }

.sidebar.closed {
  left: -174px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end; }
  .sidebar.closed header a {
    float: right; }
    .sidebar.closed header a img {
      height: 24px; }
  .sidebar.closed .navigation p {
    display: none; }
  .sidebar.closed .navigation .nav_top_item a {
    border-bottom: none; }
  .sidebar.closed .external_links {
    display: none; }
  .sidebar.closed footer p {
    display: none; }
  .sidebar.closed footer img {
    transform: rotate(180deg); }

@media only screen and (max-width: 992px) {
  .sidebar, .sidebar.closed {
    display: none; } }
