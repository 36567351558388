.burger-nav {
  -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.09);
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 48%;
  min-width: 260px;
  max-width: 360px;
  height: 100%;
  padding: 24px;
  background-color: #ffffff;
  right: -100%;
  visibility: hidden;
  top: 0;
  opacity: 0;
  transition: visibility 0s ease 0.3s, opacity 0.3s ease; }
  .burger-nav .close-icon {
    width: 24px;
    align-self: flex-end; }
  .burger-nav.open {
    right: 0;
    visibility: visible;
    opacity: 1;
    z-index: 100;
    transition-delay: 0s; }
  .burger-nav.full-width {
    right: 0;
    width: 100%;
    max-width: 100%; }

.burger-icon {
  height: 24px; }
