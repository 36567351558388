.app_footer {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 24px; }
  .app_footer p {
    margin: 0; }
  .app_footer .language {
    display: flex;
    align-items: center;
    cursor: pointer; }
    .app_footer .language img {
      height: 24px;
      margin-right: 8px; }

@media only screen and (max-width: 576px) {
  .app_footer {
    flex-direction: column;
    align-items: center; }
    .app_footer .language {
      margin-top: 24px; } }
