.main_modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000080;
  z-index: 1000; }
  .main_modal .close_modal_background {
    width: 100%;
    height: 100%;
    position: absolute; }
  .main_modal .modal_content {
    -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.09);
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.09);
    position: relative;
    background-color: #ffffff;
    z-index: 11;
    max-width: 700px;
    padding: 16px;
    border-radius: 8px;
    margin: 48px auto;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; }
    .main_modal .modal_content .close_icon_container {
      width: 100%;
      height: 24px;
      display: flex;
      justify-content: flex-end; }
      .main_modal .modal_content .close_icon_container .close_icon {
        width: 24px;
        cursor: pointer; }

@media only screen and (max-width: 768px) {
  .main_modal {
    background: #ffffff;
    height: auto; }
    .main_modal .modal_content {
      -webkit-box-shadow: 0 3px 10px 0 #ffffff;
      -moz-box-shadow: 0 3px 10px 0 #ffffff;
      box-shadow: 0 3px 10px 0 #ffffff;
      position: relative;
      width: 100%;
      border-radius: 0;
      max-height: 100vh;
      margin: 0 auto; } }
