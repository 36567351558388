.header {
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  background-color: #ffffff;
  z-index: 100;
  top: 16px; }
  .header.fixed {
    position: fixed; }
  .header.transparent {
    background-color: transparent; }
  .header .header_content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 88px; }
    .header .header_content button {
      margin-left: 16px; }
    .header .header_content .logo {
      height: 48px;
      cursor: pointer; }
    .header .header_content .burger-icon {
      display: none; }
    .header .header_content .navigation {
      display: flex; }
  .header .main_modal.session_ends_notification .modal_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center; }
    .header .main_modal.session_ends_notification .modal_content button {
      margin-top: 24px; }

@media only screen and (max-width: 992px) {
  .header .header_content {
    padding: 0 24px; } }

@media only screen and (max-width: 768px) {
  .header .header_content .navigation button {
    display: none; }
  .header .header_content .burger-icon {
    display: block; } }

@media only screen and (max-width: 576px) {
  .header .header_content .logo {
    margin: 0 auto; } }

.header .burger-nav .logo-in-burger {
  width: 170px;
  margin: 0 auto;
  display: inline-block; }

.header .burger-nav .burger-buttons {
  margin: 0 auto;
  height: 100%;
  width: 100%;
  max-width: 300px;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .header .burger-nav .burger-buttons a .main_button.regular {
    width: 100%;
    margin: 0 auto 16px auto; }
    .header .burger-nav .burger-buttons a .main_button.regular a {
      color: inherit !important; }
    .header .burger-nav .burger-buttons a .main_button.regular:hover a {
      color: white !important; }
      .header .burger-nav .burger-buttons a .main_button.regular:hover a:hover {
        color: white !important; }

a {
  color: inherit !important; }
