.trailSection {
  width: 88%;
  max-width: 1420px;
  margin: 64px auto;
  display: flex;
  justify-content: space-between; }
  .trailSection span {
    color: #00D3D2; }
  .trailSection .uppercase_bold_font {
    font-weight: 700;
    text-transform: uppercase; }

@media only screen and (max-width: 992px) {
  .trailSection {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; }
    .trailSection button {
      margin-top: 24px;
      align-self: center; } }
