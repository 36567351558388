.doYourselfSection {
  margin: 120px auto;
  width: 64%;
  max-width: 1180px; }
  .doYourselfSection .features {
    display: flex;
    justify-content: space-between;
    margin-top: 64px; }
    .doYourselfSection .features .feature {
      display: flex;
      flex-direction: column; }
      .doYourselfSection .features .feature img {
        margin-bottom: 24px;
        height: 88px; }

@media only screen and (max-width: 768px) {
  .doYourselfSection .features {
    flex-wrap: wrap; }
    .doYourselfSection .features .feature {
      text-align: center;
      width: 50%;
      margin-bottom: 16px; } }

@media only screen and (max-width: 576px) {
  .doYourselfSection .features {
    flex-direction: column;
    align-items: center; }
    .doYourselfSection .features .feature {
      text-align: center; }
      .doYourselfSection .features .feature p {
        margin-bottom: 24px; } }
