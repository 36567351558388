.checkoutSection {
  background-color: #F9F9F9;
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  border-radius: 4px;
  margin-bottom: 88px; }
  .checkoutSection .background {
    width: 88%;
    max-width: 1420px;
    margin: 64px auto;
    align-items: center;
    padding: 64px 0;
    display: flex;
    justify-content: space-between; }
    .checkoutSection .background .right {
      display: block;
      width: 49%;
      max-width: 480px;
      text-align: right; }
      .checkoutSection .background .right .checkout-logos-n-btn {
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .checkoutSection .background .right .checkout-logos-n-btn .checkout-logos {
          display: flex; }
          .checkoutSection .background .right .checkout-logos-n-btn .checkout-logos .checkout-type-logo {
            max-height: 24px;
            margin-right: 16px; }
            .checkoutSection .background .right .checkout-logos-n-btn .checkout-logos .checkout-type-logo:last-child {
              margin-right: 0; }
      .checkoutSection .background .right h3, .checkoutSection .background .right p {
        color: #000;
        margin-bottom: 24px; }
    .checkoutSection .background .left {
      width: 49%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center; }
      .checkoutSection .background .left img {
        display: block; }
      .checkoutSection .background .left img:first-child {
        max-width: 100%;
        margin-bottom: -120px; }

@media only screen and (max-width: 992px) {
  .checkoutSection {
    background-position: center; }
    .checkoutSection .background {
      flex-direction: column;
      align-items: center;
      text-align: center; }
      .checkoutSection .background .left, .checkoutSection .background .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        text-align: center;
        text-align: center; }
      .checkoutSection .background .left {
        order: 2;
        width: 88%; }
      .checkoutSection .background .right {
        order: 1;
        margin-bottom: 24px;
        width: 88%;
        text-align: center; }
        .checkoutSection .background .right .checkout-logos-n-btn {
          flex-direction: column; }
          .checkoutSection .background .right .checkout-logos-n-btn .checkout-logos {
            margin-bottom: 24px; }
        .checkoutSection .background .right .my-auto img, .checkoutSection .background .right img:first-child {
          max-width: 250px; } }
