.use_free_app_section {
  width: 88%;
  max-width: 1420px;
  margin: 64px auto;
  margin: 64px auto; }
  .use_free_app_section .slider {
    margin-top: 64px;
    display: flex;
    justify-content: space-between; }
    .use_free_app_section .slider .text .slide {
      max-width: 580px;
      padding: 16px;
      cursor: pointer; }
      .use_free_app_section .slider .text .slide:hover {
        background-color: #99FFFF20; }
    .use_free_app_section .slider .text .slide-switchers {
      display: none; }
    .use_free_app_section .slider .images {
      display: flex;
      margin-top: 48px; }
      .use_free_app_section .slider .images img {
        height: 48px;
        margin-right: 24px;
        cursor: pointer; }
        .use_free_app_section .slider .images img:hover {
          opacity: 0.75; }
    .use_free_app_section .slider .main_image {
      display: flex;
      align-items: center; }
      .use_free_app_section .slider .main_image img {
        height: 600px;
        min-height: 480px; }

@media only screen and (max-width: 1200px) {
  .use_free_app_section .slider .text, .use_free_app_section .slider .main_image {
    width: 50%; }
  .use_free_app_section .slider .main_image img {
    height: auto;
    min-height: auto;
    width: 100%; } }

@media only screen and (max-width: 768px) {
  .use_free_app_section .slider .text {
    width: 100%;
    text-align: center; }
    .use_free_app_section .slider .text .slide {
      margin: 0 auto; }
  .use_free_app_section .slider .main_image {
    display: none; }
  .use_free_app_section .images {
    justify-content: center; }
    .use_free_app_section .images .download-icons {
      width: 49%;
      max-width: 150px; }
      .use_free_app_section .images .download-icons img {
        height: auto; } }

@media only screen and (max-width: 576px) {
  .use_free_app_section .slider .text .slides-items {
    display: flex;
    overflow-x: hidden;
    -webkit-scroll-snap-points-x: repeat(100%);
    -ms-scroll-snap-points-x: repeat(100%);
    scroll-snap-points-x: repeat(100%);
    -webkit-scroll-snap-type: mandatory;
    -ms-scroll-snap-type: mandatory;
    scroll-snap-type: mandatory;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    scroll-behavior: smooth; }
    .use_free_app_section .slider .text .slides-items .slide {
      flex-shrink: 0;
      width: 100%;
      padding: 24px; }
  .use_free_app_section .slider .text .images {
    height: 16px; }
    .use_free_app_section .slider .text .images a:first-child img {
      margin-right: 0; } }
