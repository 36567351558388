.app_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 48px;
  background-color: #ffffff;
  height: 88px;
  -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.09); }
  .app_header p {
    margin: 0; }
  .app_header .left {
    display: flex;
    align-items: center;
    height: 88px; }
    .app_header .left .logo {
      height: 48px;
      cursor: pointer; }
  .app_header .right {
    display: flex;
    align-items: center; }
    .app_header .right .header-notification {
      margin-right: 24px; }
      .app_header .right .header-notification .icon {
        position: relative; }
        .app_header .right .header-notification .icon img {
          width: 24px;
          cursor: pointer; }
        .app_header .right .header-notification .icon .circle {
          width: 8px;
          height: 8px;
          border-radius: 8px;
          background-color: #EB001B;
          position: absolute;
          right: 4px;
          top: 6px; }

@media only screen and (max-width: 992px) {
  .app_header .left .logo {
    height: 36px; } }
