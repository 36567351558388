.login-component {
  background-color: #F5F6F6;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0; }
  .login-component #icon_password_login-password {
    top: 18px !important; }
  .login-component .form_input {
    margin-bottom: 0 !important; }
  .login-component input.input {
    background: white !important;
    border-radius: 2px !important;
    height: 56px !important;
    color: #333 !important;
    font-size: 16px !important; }
  .login-component input.input:not(.error) {
    border: 1px solid #e8e8e8 !important; }
  .login-component input.input:not(.valid-input) {
    border: 1px solid #e8e8e8 !important; }
  .login-component input.input:not(.error):focus {
    border: 1px solid #808080 !important; }
  .login-component input.input.error {
    border: 1px solid #E32929 !important; }
  .login-component input.input.valid-input:not(:placeholder-shown) {
    border: 1px solid #0170E3 !important; }
  .login-component ::placeholder {
    color: #B3B3B3 !important;
    font-weight: normal !important; }
  @media (min-width: 1367px) and (max-width: 1440px) {
    .login-component {
      background-position: top center;
      background-size: 85%; } }
  @media (max-width: 1366px) {
    .login-component {
      background-position: bottom center;
      background-size: 84%; } }
  .login-component .login-page-title {
    text-align: left;
    margin-bottom: 37px;
    font-size: 26px; }
  .login-component .forgot_password_section {
    font-size: 14px;
    margin-bottom: 18px;
    text-align: right;
    color: #0170e3;
    font-weight: bold; }
    .login-component .forgot_password_section a {
      color: #0170E3 !important; }
      .login-component .forgot_password_section a:hover {
        color: #399AFF !important; }
  .login-component .centered_button {
    display: flex;
    justify-content: center;
    align-items: center; }
    .login-component .centered_button button {
      width: 100%; }
  .login-component .dont_have_account {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 300;
    margin-top: 24px;
    text-align: center; }
    .login-component .dont_have_account span {
      width: 24%;
      height: 1px;
      background-color: #E5E5E5; }
  .login-component .form_container {
    width: 100%;
    min-width: 300px;
    padding: 0;
    max-width: 486px; }

.modal_content {
  max-width: 450px !important; }

.CardWhite {
  width: 100%;
  height: 100%;
  margin: auto;
  background: #f8fafb;
  overflow: hidden;
  padding: 20px 30px; }
  @media (min-width: 600px) {
    .CardWhite {
      width: 80%;
      max-width: 1318px;
      height: 85%;
      max-height: 910px;
      box-shadow: 0px 1px 40px rgba(0, 0, 0, 0.1);
      border-radius: 10px; } }
  @media (min-height: 863px) and (max-height: 900px) {
    .CardWhite {
      min-height: 800px; } }

@media (min-width: 992px) {
  .LoginGrid {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 0px; } }

.LoginImage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  position: relative; }
  @media (max-width: 991px) {
    .LoginImage {
      display: none; } }
  .LoginImage .positionAbsolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 145px; }
    @media (max-width: 1366px) {
      .LoginImage .positionAbsolute {
        left: 0; } }
  .LoginImage img {
    max-width: 559px;
    width: 95%; }
    @media (min-width: 1367px) {
      .LoginImage img {
        width: 100%; } }
    @media (min-width: 1199px) and (max-width: 1366px) {
      .LoginImage img {
        width: 100%; } }

.bgBlueButton {
  color: #ffffff;
  text-transform: uppercase;
  padding: 18px 48px !important;
  width: 100%;
  background: #0170e3 !important;
  box-shadow: 0px 6px 6px rgba(143, 235, 227, 0.25) !important;
  border-radius: 2px !important;
  font-size: 16px !important;
  font-weight: normal !important; }
  .bgBlueButton:hover {
    box-shadow: 0px 6px 6px rgba(143, 235, 227, 0.25) !important;
    background: #399aff !important; }

.LoginFormSection {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative; }
  .LoginFormSection .positionAbsolute1 {
    position: absolute;
    top: 0;
    right: 0;
    width: 145px; }
    @media (min-width: 992px) {
      .LoginFormSection .positionAbsolute1 {
        display: none; } }

.register_section {
  margin: 30px 0;
  color: #333333;
  font-size: 16px;
  font-weight: bold; }
  @media (max-width: 767px) {
    .register_section {
      width: 100%;
      text-align: left; } }
  .register_section a {
    color: #0170E3 !important; }
    .register_section a:hover {
      color: #399AFF !important; }

.beforeAfterSection {
  margin-bottom: 44px;
  width: 100%;
  position: relative;
  z-index: 1;
  padding: 0 24px; }
  .beforeAfterSection:before {
    border-top: 1px solid #b3b3b3;
    content: "";
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 90%;
    max-width: 486px;
    min-width: 300px;
    z-index: -1; }
    @media (max-width: 360px) {
      .beforeAfterSection:before {
        min-width: 275px; } }
  .beforeAfterSection span {
    background: #f8fafb;
    padding: 0 15px;
    color: #b3b3b3;
    font-size: 12px; }

.Soc_Section {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center; }

.socButton {
  background: white !important;
  border: 1px solid #4867aa !important;
  border-radius: 2px !important;
  padding: 14px 20px !important;
  width: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }
  @media (min-width: 400px) {
    .socButton {
      padding: 14px 40px !important;
      width: 146px; } }
  .socButton:hover {
    background: transparent !important; }

.F1, .G1 {
  display: none; }

.FbBlue {
  width: 100%;
  height: 100%;
  border: 1px solid #4867aa !important; }
  .FbBlue:hover {
    background: #4867aa !important; }
    .FbBlue:hover .F1 {
      display: flex; }
    .FbBlue:hover .F {
      display: none; }

.GRed {
  width: 100%;
  height: 100%;
  border: 1px solid #da4f42 !important;
  margin-left: 24px; }
  .GRed:hover {
    background: #da4f42 !important; }
    .GRed:hover .G1 {
      display: flex; }
    .GRed:hover .G {
      display: none; }
