.upgrade-modal-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center; }
  .upgrade-modal-message img {
    height: 88px;
    margin-bottom: 24px; }
  .upgrade-modal-message .message-text div p:first-child {
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 700; }
