.main-cover {
  width: 100%;
  height: 160px;
  background-color: #E5E5E5;
  overflow: hidden; }
  .main-cover .blur {
    width: 100%;
    height: 160px;
    filter: blur(8px);
    -webkit-filter: blur(8px);
    background-position: center; }
