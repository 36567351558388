.welcome-page .homepage-card {
  background-color: #00000060;
  justify-items: center;
  position: relative;
  height: 100%;
  color: #fff;
  padding-right: 290px;
  padding-left: 100px;
  margin: 0 auto; }
  .welcome-page .homepage-card h1 {
    color: #fff; }
  .welcome-page .homepage-card .homepage-card-graphics {
    opacity: .4; }
    .welcome-page .homepage-card .homepage-card-graphics img {
      display: inline-block; }
  .welcome-page .homepage-card .homepage-card-description {
    max-width: 600px; }

.welcome-page .homepage-card.main_card .card_content {
  padding: 8px;
  background-color: #ffffffb3;
  border-radius: 8px; }

@media only screen and (max-width: 992px) {
  .welcome-page .homepage-card.main_card {
    background-position: bottom; }
    .welcome-page .homepage-card.main_card .card_content {
      padding: 0;
      margin: 0 auto;
      text-align: center;
      max-width: none;
      width: 100%; } }

@media only screen and (max-width: 768px) {
  .welcome-page .homepage-card.main_card {
    padding-bottom: 370px;
    max-height: 700px; }
    .welcome-page .homepage-card.main_card .card_content {
      background-color: #ffffff00; } }

@media only screen and (max-width: 576px) {
  .welcome-page .homepage-card.main_card {
    padding-bottom: 270px; } }

@media only screen and (max-width: 320px) {
  .welcome-page .homepage-card.main_card {
    padding-bottom: 170px; } }

.premiumSection .list-unstyled {
  font-size: 24px;
  font-weight: 700; }

@media only screen and (max-width: 1200px) {
  .premiumSection .checklist {
    margin-bottom: 24px; } }

.social-commerce-section {
  height: 600px;
  color: #ffffff;
  background-size: cover;
  background-position: center; }
  .social-commerce-section .inner-container {
    height: 100%;
    background-repeat: no-repeat;
    background-position: 0 bottom; }
    .social-commerce-section .inner-container .dark-background {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 24px 120px 24px 64%;
      background-color: #00000040; }
      .social-commerce-section .inner-container .dark-background .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center; }
  @media only screen and (max-width: 992px) {
    .social-commerce-section .inner-container .dark-background {
      padding: 24px 64px;
      background-color: #00000080; } }
