.user_menu {
  display: flex;
  align-items: center;
  color: #000000; }
  .user_menu .brand-name {
    margin-right: 16px;
    color: #000000; }
    .user_menu .brand-name.white-name {
      color: #ffffff; }
    .user_menu .brand-name.dark-name {
      color: #000000; }
  .user_menu .hello_text {
    margin-right: 16px;
    display: flex;
    align-items: center; }
  .user_menu .user_initials {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-weight: 700;
    border-radius: 24px;
    text-transform: uppercase;
    cursor: pointer; }
    .user_menu .user_initials img {
      width: 24px; }
    .user_menu .user_initials.circle {
      background: #00D3D2;
      background: -moz-linear-gradient(left, #84DBFF 0%, #4AB1FF 100%);
      background: -webkit-linear-gradient(left, #84DBFF 0%, #4AB1FF 100%);
      background: linear-gradient(to right, #84DBFF 0%, #4AB1FF 100%); }
  .user_menu .circle:hover img {
    width: 30px; }
  .user_menu .user_dropdown {
    -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.09);
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.09);
    background-color: #ffffff;
    position: absolute;
    right: 48px;
    top: 88px;
    z-index: 100;
    border-radius: 8px; }
    .user_menu .user_dropdown header {
      margin: 16px;
      margin-right: 48px; }
      .user_menu .user_dropdown header p {
        margin-bottom: 8px; }
    .user_menu .user_dropdown nav ul {
      margin-left: 0;
      margin-bottom: 0;
      padding: 16px;
      cursor: pointer;
      display: flex;
      align-items: center; }
      .user_menu .user_dropdown nav ul:hover {
        background-color: #F3F5F6; }
      .user_menu .user_dropdown nav ul:last-child:hover {
        border-radius: 0 0 8px 8px; }
      .user_menu .user_dropdown nav ul img {
        width: 24px;
        margin-right: 8px; }
      .user_menu .user_dropdown nav ul a {
        color: #3E3E3E; }
        .user_menu .user_dropdown nav ul a:hover {
          text-decoration: none; }
    .user_menu .user_dropdown.dropdown_on_landing {
      right: 88px; }
  .user_menu.user-menu-on-homepage .hello_text {
    display: none; }

.user_icon_loader {
  width: 48px;
  height: 48px; }

@media only screen and (max-width: 992px) {
  .user_menu .brand-name {
    display: none; } }

@media only screen and (max-width: 576px) {
  .user_menu .user_dropdown {
    width: 90%;
    right: 5%;
    word-break: break-all; }
    .user_menu .user_dropdown.dropdown_on_landing {
      right: 5%; } }
