.main_button {
  padding: 8px 24px;
  background-color: #ffffff;
  border-radius: 64px;
  border: none;
  color: #00D3D2;
  font-family: "Roboto Slab", Georgia, "Times New Roman", Times, "DejaVu Sans", serif;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }
  .main_button .btn-loader {
    position: absolute; }
  .main_button a {
    text-decoration: none; }
  .main_button:focus {
    outline: none; }
  .main_button.regular:hover {
    background-color: #00D3D2; }
    .main_button.regular:hover span {
      color: #ffffff; }
  .main_button.regular.loadable-btn:hover {
    background-color: #ffffff; }
  .main_button.with-icon {
    text-align: left;
    padding-right: 64px;
    background-color: #00D3D2;
    color: #ffffff;
    position: relative; }
    .main_button.with-icon img {
      position: absolute;
      width: 24px;
      right: 16px; }
    .main_button.with-icon:hover {
      opacity: 0.7; }
  .main_button.primary {
    background: #00D3D2;
    background: -moz-linear-gradient(left, #00D3D2 0%, #2af59a 100%);
    background: -webkit-linear-gradient(left, #00D3D2 0%, #2af59a 100%);
    background: linear-gradient(to right, #00D3D2 0%, #2af59a 100%); }
    .main_button.primary {
      padding: 0; }
    .main_button.primary .white_bg_for_hover {
      color: #ffffff;
      text-transform: uppercase;
      padding: 16px 48px;
      font-weight: 700;
      border-radius: 64px;
      width: 100%; }
    .main_button.primary:hover .white_bg_for_hover {
      background-color: #ffffff30;
      -webkit-box-shadow: 0 0 24px 0 #00D3D2;
      -moz-box-shadow: 0 0 24px 0 #00D3D2;
      box-shadow: 0 0 24px 0 #00D3D2; }
  .main_button.transparent {
    background: transparent;
    color: #ffffff; }
  .main_button.white-bordered {
    border: 1px solid #ffffff; }
    .main_button.white-bordered:hover {
      background-color: #00D3D2;
      border-color: #00D3D2; }
  .main_button.uppercase {
    text-transform: uppercase; }
  .main_button.outlined {
    border: 1px solid #00D3D2; }
    .main_button.outlined:hover {
      background-color: #00D3D2; }
      .main_button.outlined:hover p, .main_button.outlined:hover span {
        color: #ffffff; }
  .main_button.danger {
    color: #ffffff;
    background-color: #EB001B; }
    .main_button.danger:hover {
      opacity: 0.8;
      background-color: #EB001B; }
  .main_button.disabled, .main_button.primary .white_bg_for_hover.disabled {
    opacity: 0.5; }
    .main_button.disabled:hover, .main_button.primary .white_bg_for_hover.disabled:hover {
      box-shadow: none; }
  .main_button.onWhite {
    color: #000000; }
  .main_button .invisible-txt {
    opacity: 0; }
