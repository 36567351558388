.top-notification {
  -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.09);
  left: 0;
  right: 0;
  width: 100%;
  height: 64px;
  background-color: #000000;
  position: fixed;
  color: #ffffff;
  z-index: 1000;
  display: flex;
  align-items: center;
  padding: 0 24px; }
  .top-notification.error {
    background-color: #EB001B; }
  .top-notification.success {
    background-color: #87CA95; }
  .top-notification.show {
    top: 0; }
  .top-notification.hide {
    top: -88px; }
  .top-notification p {
    margin: 0 auto;
    text-align: center; }
  .top-notification img {
    width: 24px;
    cursor: pointer;
    position: absolute;
    right: 24px;
    transform: rotate(45deg); }
