.error-page-container {
  width: 100%;
  min-height: 100vh;
  background: no-repeat center;
  background-size: cover; }
  .error-page-container .content-container {
    width: 88%;
    max-width: 800px;
    margin: 0 auto;
    padding-top: 88px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center; }
    .error-page-container .content-container h1, .error-page-container .content-container h2, .error-page-container .content-container h3, .error-page-container .content-container h4, .error-page-container .content-container h5, .error-page-container .content-container h6, .error-page-container .content-container p {
      color: #ffffff; }
    .error-page-container .content-container a {
      display: inline;
      margin: 48px auto; }
  .error-page-container .form_footer {
    position: absolute;
    bottom: 0;
    background: none;
    color: #ffffff; }
