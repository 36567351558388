.featuresSection {
  width: 88%;
  max-width: 1420px;
  margin: 64px auto;
  border-bottom: 1px solid #E5E5E5;
  padding-bottom: 64px; }
  .featuresSection .features {
    display: flex;
    flex-wrap: wrap; }
    .featuresSection .features .feature {
      margin-top: 64px;
      text-align: center;
      justify-content: space-between;
      width: 20%;
      padding: 0 8px; }
      .featuresSection .features .feature img {
        height: 88px;
        margin-bottom: 48px; }

@media only screen and (max-width: 992px) {
  .featuresSection .features .feature {
    width: 33.33333%; } }

@media only screen and (max-width: 576px) {
  .featuresSection .features .feature {
    width: 50%; } }
