.app-responsive-header {
  display: none; }
  @media only screen and (max-width: 992px) {
    .app-responsive-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 64px;
      background-color: #3E3E3E; }
      .app-responsive-header .logo {
        height: 48px; }
      .app-responsive-header .nav-content-container {
        height: 100vh;
        overflow-x: auto; }
        .app-responsive-header .nav-content-container .nav-content header {
          display: flex;
          font-weight: 700;
          position: relative; }
          .app-responsive-header .nav-content-container .nav-content header .initials {
            background: #00D3D2;
            background: -moz-linear-gradient(left, #84DBFF 0%, #4AB1FF 100%);
            background: -webkit-linear-gradient(left, #84DBFF 0%, #4AB1FF 100%);
            background: linear-gradient(to right, #84DBFF 0%, #4AB1FF 100%);
            margin-right: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            border-radius: 64%;
            text-transform: uppercase;
            color: #ffffff; }
          .app-responsive-header .nav-content-container .nav-content header .fullname p {
            margin: 0; }
        .app-responsive-header .nav-content-container .nav-content .burger-nav-items {
          margin-top: 24px; }
          .app-responsive-header .nav-content-container .nav-content .burger-nav-items div {
            border-bottom: 1px solid #F2F2F2;
            padding: 16px 0; }
            .app-responsive-header .nav-content-container .nav-content .burger-nav-items div a {
              display: flex;
              color: #000000; }
              .app-responsive-header .nav-content-container .nav-content .burger-nav-items div a img {
                height: 24px;
                margin-right: 16px; }
              .app-responsive-header .nav-content-container .nav-content .burger-nav-items div a p {
                margin: 0; }
      .app-responsive-header .burger-nav footer {
        display: flex;
        position: absolute;
        width: 88%;
        bottom: 0;
        padding: 16px 0;
        border-top: 1px solid #F2F2F2;
        background-color: #ffffff; }
        .app-responsive-header .burger-nav footer p {
          margin: 0; }
        .app-responsive-header .burger-nav footer img {
          height: 24px;
          margin-right: 16px; } }
