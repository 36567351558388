.hero, .mobile-hero {
  width: 100%;
  height: 70vh;
  min-height: 500px;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center -15vh; }
  .hero .background, .mobile-hero .background {
    background-color: #00000070;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center; }
    .hero .background .content_container, .mobile-hero .background .content_container {
      margin: 0 auto;
      max-width: 1420px;
      display: flex;
      justify-content: space-between;
      padding: 120px 120px 0 120px;
      align-items: center;
      width: 100%; }
      .hero .background .content_container .hero-content-description, .mobile-hero .background .content_container .hero-content-description {
        max-width: 650px; }
      .hero .background .content_container .hero-content-graph, .mobile-hero .background .content_container .hero-content-graph {
        max-width: 450px;
        margin-bottom: -100px; }
        .hero .background .content_container .hero-content-graph img, .mobile-hero .background .content_container .hero-content-graph img {
          height: 65vh;
          min-height: 400px; }
      .hero .background .content_container .main_button, .mobile-hero .background .content_container .main_button {
        margin-top: 48px;
        margin-bottom: 24px; }
      .hero .background .content_container div.line, .mobile-hero .background .content_container div.line {
        display: none; }
      .hero .background .content_container h1, .hero .background .content_container h2, .hero .background .content_container h3, .hero .background .content_container h4, .hero .background .content_container h5, .hero .background .content_container h6, .hero .background .content_container p, .hero .background .content_container div.line, .mobile-hero .background .content_container h1, .mobile-hero .background .content_container h2, .mobile-hero .background .content_container h3, .mobile-hero .background .content_container h4, .mobile-hero .background .content_container h5, .mobile-hero .background .content_container h6, .mobile-hero .background .content_container p, .mobile-hero .background .content_container div.line {
        color: #ffffff;
        margin-bottom: 24px; }

.mobile-hero {
  display: none;
  z-index: -10; }
  @media only screen and (max-width: 992px) {
    .mobile-hero {
      display: block;
      position: absolute; } }

@media only screen and (max-width: 992px) {
  .hero {
    background-attachment: scroll;
    background-image: url("http://none") !important; }
    .hero .background .content_container {
      width: 100%;
      min-width: auto;
      padding: 64px 64px 0 64px; }
      .hero .background .content_container .hero-content-description {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        margin: 0 auto; }
        .hero .background .content_container .hero-content-description .main_button {
          margin: 24px; }
        .hero .background .content_container .hero-content-description div.line {
          display: block; }
      .hero .background .content_container .hero-content-graph {
        display: none; } }

@media only screen and (max-width: 576px) {
  .hero .background .content_container {
    padding: 48px 24px 0 24px; } }
